"use client";

import React, { useRef, useId } from "react";
import { useFormatter } from "next-intl";
import { AreaClosed, LinePath, Circle, Line } from "@visx/shape";
import { curveLinear } from "@visx/curve";
import { withParentSize } from "@visx/responsive";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useTailwindTheme } from "@/hooks";
import { motion } from "framer-motion";
import { AreaChartProps, BaseDataPoint, chartClassNames, defaultMargin } from "@/edges/types/ChartProps";
import { ChartEmptyState } from "@/edges/ui/ChartEmptyState";
import { ChartTooltip } from "@/edges/ui/ChartTooltip";
import { ChartBottomBar } from "@/edges/ui/ChartBottomBar";
import { handleChartExport } from "@/edges/hooks";
import { AxisBottom } from "@/edges/ui/AxisBottom";
import { AxisLeft } from "@/edges/ui/AxisLeft";
import { createXScale, createYScale, getYFormatSettings } from "@/edges/utils/charts";
const VisXAreaChart: React.FC<AreaChartProps> = ({
  parentWidth,
  parentHeight,
  data,
  colorIndex = 0,
  xLabel,
  yLabel,
  yFormatType = "integer"
}) => {
  // Check if data is undefined, null, or empty
  if (!data || data.length === 0) {
    return <ChartEmptyState />;
  }
  const width = parentWidth;
  const height = parentHeight - 60; // Subtract ChartBottomBar height

  const {
    getThemeValue
  } = useTailwindTheme();
  const chartColors = getThemeValue("colors.charts");
  const chartFillColor = chartColors ? chartColors[colorIndex] : undefined;
  const uniqueGradientId = useId();
  const linePathRef = useRef<SVGPathElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const xScale = createXScale(data, width);
  const yScale = createYScale(data, height, yFormatType);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip
  } = useTooltip<BaseDataPoint>();
  const {
    containerRef,
    TooltipInPortal
  } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });
  const format = useFormatter();
  const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    const {
      x
    } = localPoint(event) || {
      x: 0
    };
    const x0 = xScale.invert(x);
    const nearestDataPoint = data.reduce((prev, curr) => Math.abs(curr.xValue.getTime() - x0.getTime()) < Math.abs(prev.xValue.getTime() - x0.getTime()) ? curr : prev);
    showTooltip({
      tooltipData: nearestDataPoint,
      tooltipLeft: xScale(nearestDataPoint.xValue),
      tooltipTop: yScale(nearestDataPoint.yValue)
    });
  };
  const handleExport = (type: string) => {
    if (svgRef.current) {
      handleChartExport(type, data, xLabel, yLabel, "", svgRef);
    }
  };
  return <div ref={containerRef} className={chartClassNames.container} data-sentry-component="VisXAreaChart" data-sentry-source-file="AreaChart.tsx">
      <svg width={width} height={height} ref={svgRef} className="overflow-visible" data-sentry-element="svg" data-sentry-source-file="AreaChart.tsx">
        <defs data-sentry-element="defs" data-sentry-source-file="AreaChart.tsx">
          <linearGradient id={uniqueGradientId} x1="0%" y1="0%" x2="0%" y2="100%" data-sentry-element="linearGradient" data-sentry-source-file="AreaChart.tsx">
            <stop offset="50%" stopColor={chartFillColor} stopOpacity={0.1} data-sentry-element="stop" data-sentry-source-file="AreaChart.tsx" />
            <stop offset="100%" stopColor={chartFillColor} stopOpacity={0} data-sentry-element="stop" data-sentry-source-file="AreaChart.tsx" />
          </linearGradient>
        </defs>

        <g data-sentry-element="g" data-sentry-source-file="AreaChart.tsx">
          {yScale.ticks(5).map(tickValue => <Line key={`grid-line-${tickValue}`} from={{
          x: defaultMargin.left,
          y: yScale(tickValue)
        }} to={{
          x: width - defaultMargin.right,
          y: yScale(tickValue)
        }} stroke={getThemeValue("colors.borders")} strokeWidth={1} />)}
        </g>

        <motion.g initial={{
        scaleY: 0,
        y: height - defaultMargin.bottom
      }} animate={{
        scaleY: 1,
        y: 0
      }} transition={{
        duration: 0.3,
        ease: "easeOut"
      }} style={{
        transformOrigin: "bottom"
      }} data-sentry-element="unknown" data-sentry-source-file="AreaChart.tsx">
          <AreaClosed data={data} x={d => xScale(d.xValue)} y={d => yScale(d.yValue)} yScale={yScale} fill={`url(#${uniqueGradientId})`} curve={curveLinear} data-sentry-element="AreaClosed" data-sentry-source-file="AreaChart.tsx" />
        </motion.g>

        <motion.g initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} transition={{
        delay: 0.3,
        duration: 0.3,
        ease: "easeOut"
      }} data-sentry-element="unknown" data-sentry-source-file="AreaChart.tsx">
          <LinePath data={data} x={d => xScale(d.xValue)} y={d => yScale(d.yValue)} stroke={chartFillColor} strokeWidth={3} curve={curveLinear} ref={linePathRef} data-sentry-element="LinePath" data-sentry-source-file="AreaChart.tsx" />
        </motion.g>

        <AxisBottom top={height - defaultMargin.bottom} scale={xScale} width={width} domain={xScale.domain()} data-sentry-element="AxisBottom" data-sentry-source-file="AreaChart.tsx" />

        <AxisLeft left={defaultMargin.left} scale={yScale} y data-sentry-element="AxisLeft" data-sentry-source-file="AreaChart.tsx" />

        <rect width={width - defaultMargin.left - defaultMargin.right} height={height - defaultMargin.top - defaultMargin.bottom} x={defaultMargin.left} y={defaultMargin.top} fill="transparent" onMouseMove={handleMouseMove} onMouseLeave={hideTooltip} data-sentry-element="rect" data-sentry-source-file="AreaChart.tsx" />

        {tooltipData && <>
            <Line from={{
          x: tooltipLeft,
          y: tooltipTop
        }} to={{
          x: tooltipLeft,
          y: yScale(0)
        }} stroke={getThemeValue("colors.text")} strokeWidth={1} strokeDasharray="4 2" />
            <Line from={{
          x: tooltipLeft,
          y: tooltipTop
        }} to={{
          x: xScale.range()[0],
          y: tooltipTop
        }} stroke={getThemeValue("colors.text")} strokeWidth={1} strokeDasharray="4 2" />
            <Circle cx={tooltipLeft} cy={tooltipTop} r={4} fill={chartFillColor} stroke="#ffffff" strokeWidth={2} />
          </>}
      </svg>

      {tooltipData && <div style={{
      position: "absolute",
      left: tooltipLeft,
      top: tooltipTop,
      transform: `translate(${tooltipLeft < defaultMargin.left + 100 ? "0" : tooltipLeft > width - defaultMargin.right - 100 ? "-100%" : "-50%"}, -100%)`,
      pointerEvents: "none",
      transition: tooltipData ? "opacity 0.2s ease-out, transform 0.2s ease-out" : "none",
      // Disable transition when hiding
      opacity: tooltipData ? 1 : 0
    }}>
          <ChartTooltip items={[{
        label: xLabel,
        value: format.dateTime(tooltipData.xValue, {
          dateStyle: "full",
          timeStyle: "short"
        })
      }, {
        label: yLabel,
        value: getYFormatSettings(yFormatType).format(tooltipData.yValue)
      }]} />
        </div>}

      <ChartBottomBar data={data} xLabel={xLabel} yLabel={yLabel} onExport={handleExport} items={[{
      category: yLabel,
      fill: chartFillColor,
      gradientId: uniqueGradientId
    }]} data-sentry-element="ChartBottomBar" data-sentry-source-file="AreaChart.tsx" />
    </div>;
};
export const AreaChart = withParentSize(VisXAreaChart);