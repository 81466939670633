"use client";

import React, { useEffect, useRef, useState } from "react";
import { WindowSize } from "@/@codegen/supergraph";
import { Button } from "@/edges/ui/Button";
import { Dialog } from "@/edges/ui/Dialog";
import { Icon } from "@/edges/ui/Icon";
import { Popover } from "@/edges/ui//Popover";
import { Select, SelectItem } from "@/edges/ui/Select";
import { ToggleButton } from "@/edges/ui/ToggleButton";
import { RangeCalendar } from "@/edges/ui/RangeCalendar";
import { DateValue, DialogTrigger, RangeValue } from "react-aria-components";
import { DateRanges } from "@/utils/date";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";
export interface ChartToolbarProps {
  selectedRange: DateRanges;
  onRangeChange: (range: DateRanges) => void;
  allowDatePicker: boolean;
  datePickerValue?: DateValue | null;
  onDatePickerChange?: (date: DateValue) => void;
  selectedWindowSize: WindowSize;
  onWindowSizeChange: (windowSize: WindowSize) => void;
  className?: string;
  showWindowSize?: boolean;
}
const WINDOW_SIZE_SORT_MAP = {
  [WindowSize.Hour]: 1,
  [WindowSize.Day]: 2,
  [WindowSize.Week]: 3,
  [WindowSize.Month]: 4,
  // Shouldn't be used as we are filtering but want to keep it here for future reference
  [WindowSize.Year]: 5
};
const ChartToolbar: React.FC<ChartToolbarProps> = ({
  selectedRange = DateRanges.Last7Days,
  onRangeChange,
  allowDatePicker = false,
  datePickerValue,
  onDatePickerChange,
  showWindowSize = true,
  selectedWindowSize = WindowSize.Day,
  onWindowSizeChange,
  className
}) => {
  const [componentWidth, setComponentWidth] = useState(0);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const t = useTranslations();
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setComponentWidth(entry.contentRect.width);
      }
    });
    if (toolbarRef.current) {
      resizeObserver.observe(toolbarRef.current);
    }
    return () => {
      if (toolbarRef.current) {
        resizeObserver.unobserve(toolbarRef.current);
      }
    };
  }, []);
  const ranges = Object.values(DateRanges).map(range => ({
    label: t(`common.${range.toLowerCase()}`),
    value: range
  }));
  const windowSize = Object.values(WindowSize).filter(size => size !== WindowSize.Year).sort((a: WindowSize, b: WindowSize) => WINDOW_SIZE_SORT_MAP[a] - WINDOW_SIZE_SORT_MAP[b]).map(size => ({
    id: size,
    label: t(`common.${size.toLowerCase()}`)
  }));
  const handleRangeChange = (value: string) => {
    onRangeChange(value as DateRanges);
  };
  const handleWindowSizeChange = (value: string) => {
    onWindowSizeChange(value as WindowSize);
  };
  const dataWindowSizeSelect = <Select className="w-full md:w-auto" selectedKey={selectedWindowSize} onSelectionChange={handleWindowSizeChange} size="md" aria-label="Select Window Size">
      {windowSize.map(size => <SelectItem key={size.id} id={size.id} aria-label={size.label}>
          {size.label}
        </SelectItem>)}
    </Select>;
  const datePicker = <>
      <DialogTrigger>
        <Button className={`rounded-r-none border-l pl-2 pr-2 hover:border-borders hover:bg-gray-200 hover:text-primary ${datePickerValue ? "rounded-r-sm bg-gray-200" : "font-semibold"}`} onPress={() => setIsDatePickerOpen(!isDatePickerOpen)} variant="secondary">
          <Icon name="CalendarBlank" size={18} />
          {datePickerValue && <span className="ml-2 hidden md:block">
              {`${datePickerValue.start.toDate().toLocaleDateString()} - ${datePickerValue.end.toDate().toLocaleDateString()}`}
            </span>}
        </Button>
        <Popover className="w-full md:w-auto">
          <Dialog>
            <RangeCalendar aria-label="Date range calendar" value={datePickerValue as RangeValue<DateValue>} onChange={onDatePickerChange ? (value: RangeValue<DateValue> | null) => onDatePickerChange(value) : () => {}} />
          </Dialog>
        </Popover>
      </DialogTrigger>
      {datePickerValue && <Button variant="ghost" size="md" onPress={() => onDatePickerChange(null)}>
          Reset
        </Button>}
    </>;
  return <div ref={toolbarRef} className={twMerge("flex items-center justify-between", className)} data-sentry-component="ChartToolbar" data-sentry-source-file="ChartToolbar.tsx">
      {/* Render differently based on component width */}

      {componentWidth < 600 ?
    // Compact controls
    <div className="flex w-full justify-between">
          <div className="flex items-center">
            {allowDatePicker && datePicker}
            {!datePickerValue && <Select selectedKey={selectedRange} onSelectionChange={handleRangeChange} size="md" aria-label="Select Date Range">
                {ranges.map(range => <SelectItem key={range.value} id={range.value}>
                    {range.label}
                  </SelectItem>)}
              </Select>}
          </div>
          <div className="flex items-center">{dataWindowSizeSelect}</div>
        </div> :
    // Exposed controls
    <>
          <div className="hidden items-center md:flex">
            {/* allowDatePicker && datePicker */}
            {!datePickerValue && ranges.map((range, index) => <ToggleButton key={range.value} size="md" isSelected={selectedRange === range.value} onChange={() => handleRangeChange(range.value)} borderRadius={index === 0 ? "left" : index === ranges.length - 1 ? "right" : undefined} borderLeft={true} borderRight={index === ranges.length - 1}>
                  {range.label}
                </ToggleButton>)}
          </div>
          <div className="ml-auto items-center md:static md:flex md:w-auto">
            {showWindowSize && dataWindowSizeSelect}
          </div>
        </>}
    </div>;
};
export { ChartToolbar };