"use client";

import React from "react";
interface TooltipItem {
  label: string;
  value: number | string;
  color?: string;
}
interface ChartTooltipProps {
  items: TooltipItem[];
  className?: string;
}
export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  items
}) => {
  if (!items?.length) {
    return null;
  }
  return <div role="tooltip" aria-label="Chart data details" className="w-max rounded-lg bg-slate-600 px-3 py-4 text-sm shadow-2xl" data-sentry-component="ChartTooltip" data-sentry-source-file="ChartTooltip.tsx">
      <div className="flex gap-5">
        <div className="flex flex-col gap-2">
          {items.map((item, index) => <div key={index} className="text-gray-300">
              {item.label}
            </div>)}
        </div>
        <div className="ml-4 flex flex-col gap-2">
          {items.map((item, index) => <div key={index} className="flex items-center justify-end gap-2">
              {item.color && <div className="h-[12px] w-[12px] rounded-sm border border-slate-500" style={{
            backgroundColor: item.color
          }} />}
              <div key={index} className="text-white">
                {item.value}
              </div>
            </div>)}
        </div>
      </div>
    </div>;
};