"use client";

import React, { ReactElement } from "react";
import { DateRanges } from "@/utils/date";
import { WindowSize } from "@/@codegen/supergraph";
import { AreaChart } from "@/edges/ui//AreaChart";
import { Card } from "@/edges/ui/Card";
import { ChartToolbar } from "@/edges/ui/ChartToolbar/ChartToolbar";
import { GroupedBarChart } from "@/edges/ui/GroupedBarChart";
import { Select, SelectItem } from "@/edges/ui/Select";
import { Skeleton } from "@/edges/ui/Skeleton";
type AllowedCharts = ReactElement<typeof AreaChart> | ReactElement<typeof GroupedBarChart>;
interface ChartCardProps {
  // common props
  title?: string;
  isGhost?: boolean;
  withPadding?: boolean;
  isLoading?: boolean;
  children?: AllowedCharts;
  // chart toolbar props
  selectedRange?: DateRanges;
  onRangeChange?: (range: DateRanges) => void;
  selectedWindowSize?: WindowSize;
  onWindowSizeChange?: (value: WindowSize) => void;
  allowDatePicker?: boolean;
  showWindowSize?: boolean;
  // dataset selector props
  datasetOptions?: {
    label: string;
    value: string;
  }[];
  selectedDataset?: string;
  onDatasetChange?: (value: string) => void;
  // category selector props
  categoryOptions?: {
    label: string;
    value: string;
  }[];
  selectedCategory?: string;
  onCategoryChange?: (value: string) => void;
}
export type Dataset<DataType = any> = {
  title: string;
  data: DataType[];
  xKey: string;
  yKey: string;
  xLabel?: string;
  yLabel?: string;
  colorIndex?: number;
  datasetOptions?: {
    label: string;
    value: string;
  }[];
  selectedDataset?: string;
  onDatasetChange?: (value: string) => void;
  isLoading?: boolean;
  showWindowSize?: boolean;
  children?: AllowedCharts;
};
export const ChartCard: React.FC<ChartCardProps> = ({
  title,
  selectedRange = DateRanges.Last7Days,
  onRangeChange,
  showWindowSize = true,
  selectedWindowSize = WindowSize.Day,
  onWindowSizeChange,
  onDatasetChange,
  datasetOptions,
  categoryOptions,
  selectedDataset,
  selectedCategory,
  onCategoryChange,
  isLoading,
  isGhost = false,
  withPadding = true,
  allowDatePicker = false,
  children
}) => {
  return <Card heading={title} className="flex flex-col" isGhost={isGhost} withPadding={withPadding} data-sentry-element="Card" data-sentry-component="ChartCard" data-sentry-source-file="ChartCard.tsx">
      {/* Chart toolbar */}
      <ChartToolbar allowDatePicker={allowDatePicker} selectedRange={selectedRange} onRangeChange={range => onRangeChange?.(range)} showWindowSize={showWindowSize} selectedWindowSize={selectedWindowSize} onWindowSizeChange={windowSize => onWindowSizeChange?.(windowSize)} className="pb-3 pt-2" data-sentry-element="ChartToolbar" data-sentry-source-file="ChartCard.tsx" />
      {/* Dataset selector - only applicable if datasetOptions are provided */}
      <>
        <div className="my-2 flex items-center gap-4">
          {datasetOptions && <Select size="md" selectedKey={selectedDataset} onSelectionChange={onDatasetChange} aria-label="Select Dataset" className="max-w-[240px] pb-2">
              {datasetOptions?.map(option => <SelectItem key={option.value} id={option.value}>
                  {option.label}
                </SelectItem>)}
            </Select>}
          {categoryOptions && <div className="flex items-center gap-4">
              <span className="relative bottom-[3px] text-sm">by</span>
              <Select size="md" selectedKey={selectedCategory} onSelectionChange={onCategoryChange} aria-label="Select Category" className="max-w-[240px] pb-2">
                {categoryOptions?.map(option => <SelectItem key={option.value} id={option.value}>
                    {option.label}
                  </SelectItem>)}
              </Select>
            </div>}
        </div>
        {/* Chart */}
        {isLoading ? <div className="flex h-full w-full flex-col items-center justify-center gap-6">
            <div className="flex items-end gap-4">
              <Skeleton className="h-[100px] w-[50px]" />
              <Skeleton className="h-[140px] w-[50px]" />
              <Skeleton className="h-[120px] w-[50px]" />
              <Skeleton className="h-[160px] w-[50px]" />
            </div>
          </div> : <div className="h-[500px] max-h-[500px]">{children}</div>}
      </>
    </Card>;
};