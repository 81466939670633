const legacyTheme = {
  colors: {
    // Although this is textureBlue we'll slowly replace it with darkIndigo name to match the design system naming
    textureDarkIndigo: "#180052",
    textureCoolGray: "#929BAA",
    textureNeutralGray: "#FBFAFC",
    textureAlmostWhiteGray: "#FBFBFB",
    textureDoveGray: "#CFD5DE",
    textureLightGray: "#F7F8FA",
    textureSoftLavender: "#E5E7FF",
    textureMediumLavender: "#D3CFDE",
    texturePlaceholder: "#BBB7C4",
    textureInputCount: "#564D6B",
    textureInvalid: "#AB220A",
    // we need to cleanup the gray colors as soon as designs allow it
    // this gray is used in titles of components in pages such as sites, customers, devices, etc.
    textureTitleGray: "#7D7D7D",
    // this gray is used in texts of components in pages such as sites, customers, devices, etc.
    textureTextGray: "#9C9C9C",
    textureBorderGray: "#E2E2E2",

    // new codes
    textureElectricBlue: "#1414C1",
    textureGlandhalf: "#EAE9F0",
    texturePennBlue: "#0C1452",
    textureIris: "#444AE1",
    textureBlue: "#180052",
    textureBlueGray: "#4D586B",
    textureGreen: "#40C98B",
    textureSuccessBannerBackground: "#E2F7F0",
    textureSuccessBannerBorder: "#40C998",
    texturePurple: "#651FFE",
    textureHover: "#FAFAFA",
    textureWhite: "#FFFFFF",
    textureWhitish: "#FFFDF8",
    textureGray: "#1D1D1D",
    textureLightGrayOld: "#DCD9E5",
    textureGrayDark: "#A8AFB8",
    textureGrayLight: "#F6F6F6",
    textureRed: "#F64138",
    textureMint: "#40C98B",
    textureOffWhite: "#F5F5F5",
    textureStormySky: "#62647D",
    // old codes
    textureBlack: "#0A0013",
    textureOrange: "#FC5508",
    textureOrangeLight: "#FA7132",
    textureYellow: "#FFC933",
    textureCream: "#F8F7F2",
    textureCharcoal: "#434343",
    textureIndigo: "#6C2FED",
    textureIndigoLight: "#FBFAFF",
  },
  extend: {
    screens: {
      "sign-up-page": "915px",
      "customer-page": "1280px",
      "profile-page-lg": "1665px",
      "profile-page-md": "1280px",
      xxs: "376px",
      xs: "431px",
    },
    fontSize: {
      "custom-extra-large": "40px",
      "custom-large": "32px",
      "custom-medium": "24px",
      "custom-small": "16px",
      "custom-extra-small": "14px",
    },
    lineHeight: {
      "custom-extra-large": "56px",
      "custom-large": "48px",
      "custom-medium": "32px",
      "custom-small": "24px",
      "custom-extra-small": "24px",
    },
    fontFamily: {
      firs: ["var(--font-inter), sans-serif"],
      inter: ["var(--font-inter), sans-serif"],
      epilogue: ["var(--font-epilogue), sans-serif"],
    },
    borderRadius: {
      3: "3px",
    },
    borderWidth: {
      1: "1px",
      1.5: "1.5px",
    },
    boxShadow: {
      strong: "0px 6px 12px 0px rgba(12, 20, 82, 0.20)",
      strongUp: "0px -6px 12px 0px rgba(12, 20, 82, 0.20)",
    },
    keyframes: {
      fadeIn: {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
      },
      fadeOut: {
        "0%": { opacity: "1" },
        "100%": { opacity: "0" },
      },
      slideInUp: {
        "0%": { transform: "translateY(100%)" },
        "100%": { transform: "translateY(0)" },
      },
      slideOutUp: {
        "0%": { transform: "translateY(0)" },
        "100%": { transform: "translateY(100%)" },
      },
      slideInRight: {
        "0%": { transform: "translateX(100%)" },
        "100%": { transform: "translateX(0)" },
      },
      slideOutRight: {
        "0%": { transform: "translateX(0)" },
        "100%": { transform: "translateX(100%)" },
      },
      slideInLeft: {
        "0%": { transform: "translateX(-100%)" },
        "100%": { transform: "translateX(0)" },
      },
      slideOutLeft: {
        "0%": { transform: "translateX(0)" },
        "100%": { transform: "translateX(-100%)" },
      },
      slideDownOpen: {
        "0%": { transform: "scaleY(0)", transformOrigin: "top" },
        "100%": { transform: "scaleY(1)", transformOrigin: "top" },
      },
      slideDownClose: {
        "0%": { transform: "scaleY(1)", transformOrigin: "top" },
        "100%": { transform: "scaleY(0)", transformOrigin: "top" },
      },
      slideUpOpen: {
        "0%": { transform: "scaleY(0)", transformOrigin: "bottom" },
        "100%": { transform: "scaleY(1)", transformOrigin: "bottom" },
      },
      slideUpClose: {
        "0%": { transform: "scaleY(1)", transformOrigin: "bottom" },
        "100%": { transform: "scaleY(0)", transformOrigin: "bottom" },
      },
    },
    animation: {
      fadeIn: "fadeIn 200ms ease-in-out forwards",
      fadeOut: "fadeOut 200ms ease-in-out forwards",
      fadeInDelay: "fadeIn 200ms ease-in-out 200ms forwards",
      fadeOutDelay: "fadeOut 200ms ease-in-out 200ms forwards",
      slideInUp: "slideInUp 200ms ease-in-out forwards",
      slideOutUp: "slideOutUp 200ms ease-in-out forwards",
      slideInRight: "slideInRight 200ms ease-in-out forwards",
      slideOutRight: "slideOutRight 200ms ease-in-out forwards",
      slideInLeft: "slideInLeft 200ms ease-in-out forwards",
      slideOutLeft: "slideOutLeft 200ms ease-in-out forwards",
      slideDownOpen: "slideDownOpen 200ms ease-in-out forwards",
      slideDownClose: "slideDownClose 200ms ease-in-out forwards",
      slideUpOpen: "slideUpOpen 200ms ease-in-out forwards",
      slideUpClose: "slideUpClose 200ms ease-in-out forwards",
    },
  },
};

export default legacyTheme;
