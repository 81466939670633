import type { Config } from "tailwindcss";
import theme from "./src/styles/theme";
import _theme from "./src/styles/legacyTheme";
import { merge } from "lodash";

const mergedTheme = merge(theme, _theme);

const config: Config = {
  content: [
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
    "./storybook/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    extend: mergedTheme,
  },
  darkMode: "class",
  plugins: [
    require("tailwindcss-react-aria-components"),
    require("tailwindcss-animate"),
  ],
};

export default config;
