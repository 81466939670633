import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import { Calendar as AriaCalendar, CalendarGridHeader as AriaCalendarGridHeader, CalendarProps as AriaCalendarProps, CalendarCell, CalendarGrid, CalendarGridBody, CalendarHeaderCell, DateValue, Heading, Text, useLocale } from "react-aria-components";
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
import { focusRing } from "@/edges/utils";
function getCellStyles({
  isSelected,
  isDisabled
}: {
  isSelected: boolean;
  isDisabled: boolean;
}) {
  const baseStyles = [focusRing, "w-9 h-9 text-base cursor-default rounded-full flex items-center justify-center forced-color-adjust-none"];
  if (isDisabled) {
    baseStyles.push("text-gray-300 dark:text-zinc-600 forced-colors:text-[GrayText]");
  } else if (isSelected) {
    baseStyles.push("bg-blue-600 invalid:bg-red-600 text-white forced-colors:bg-[Highlight] forced-colors:invalid:bg-[Mark] forced-colors:text-[HighlightText]");
  } else {
    baseStyles.push("text-zinc-900 dark:text-zinc-200 hover:bg-gray-100 dark:hover:bg-zinc-700 pressed:bg-gray-200 dark:pressed:bg-zinc-600");
  }
  return baseStyles.join(" ");
}
export interface CalendarProps<T extends DateValue> extends Omit<AriaCalendarProps<T>, "visibleDuration"> {
  errorMessage?: string;
}
export function Calendar<T extends DateValue>({
  errorMessage,
  ...props
}: CalendarProps<T>) {
  return <AriaCalendar {...props} data-sentry-element="AriaCalendar" data-sentry-component="Calendar" data-sentry-source-file="Calendar.tsx">
      <CalendarHeader data-sentry-element="CalendarHeader" data-sentry-source-file="Calendar.tsx" />
      <CalendarGrid data-sentry-element="CalendarGrid" data-sentry-source-file="Calendar.tsx">
        <CalendarGridHeader data-sentry-element="CalendarGridHeader" data-sentry-source-file="Calendar.tsx" />
        <CalendarGridBody data-sentry-element="CalendarGridBody" data-sentry-source-file="Calendar.tsx">
          {date => <CalendarCell date={date} className={({
          isSelected,
          isDisabled
        }) => getCellStyles({
          isSelected,
          isDisabled
        })} />}
        </CalendarGridBody>
      </CalendarGrid>
      {errorMessage && <Text slot="errorMessage" className="text-sm text-red-600">
          {errorMessage}
        </Text>}
    </AriaCalendar>;
}
export function CalendarHeader() {
  const {
    direction
  } = useLocale();
  const buttonSize = 18;
  return <header className="flex w-full items-center gap-1 px-1 pb-4" data-sentry-component="CalendarHeader" data-sentry-source-file="Calendar.tsx">
      <Button variant="icon" slot="previous" className="pl-0" data-sentry-element="Button" data-sentry-source-file="Calendar.tsx">
        {direction === "rtl" ? <Icon name="CaretRight" aria-hidden size={buttonSize} /> : <Icon name="CaretLeft" aria-hidden size={buttonSize} />}
      </Button>
      <Heading className="text-md mx-2 flex-1 text-center font-semibold text-zinc-900 dark:text-zinc-200" data-sentry-element="Heading" data-sentry-source-file="Calendar.tsx" />
      <Button variant="icon" slot="next" className="pr-0" data-sentry-element="Button" data-sentry-source-file="Calendar.tsx">
        {direction === "rtl" ? <Icon name="CaretLeft" aria-hidden size={buttonSize} /> : <Icon name="CaretRight" aria-hidden size={buttonSize} />}
      </Button>
    </header>;
}
export function CalendarGridHeader() {
  return <AriaCalendarGridHeader data-sentry-element="AriaCalendarGridHeader" data-sentry-component="CalendarGridHeader" data-sentry-source-file="Calendar.tsx">
      {day => <CalendarHeaderCell className="text-xs font-semibold text-gray-500">
          {day}
        </CalendarHeaderCell>}
    </AriaCalendarGridHeader>;
}