import React from "react";
import { ToggleButton as RACToggleButton } from "react-aria-components";
import { focusRing } from "@/edges/utils";
export interface toggleButtonVariants {
  isSelected?: boolean;
  borderRadius?: "left" | "right";
  borderRight?: boolean;
  borderLeft?: boolean;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
}

// Updated cn helper to handle functions
const cn = (...classes: (string | undefined | null | false | (() => string))[]) => classes.filter(Boolean).map(cls => typeof cls === "function" ? cls() : cls).join(" ");

// Base styles remain the same, just moved to template literals
const getToggleButtonClass = ({
  isSelected,
  borderRadius,
  borderRight,
  borderLeft,
  isDisabled,
  size = "md",
  className
}: toggleButtonVariants & {
  className?: string;
}) => {
  return cn(
  // Base styles
  focusRing, "px-5 text-sm text-center transition border-y dark:border-white/10 forced-colors:border-[ButtonBorder] shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] dark:shadow-none cursor-pointer forced-color-adjust-none",
  // isSelected variants
  isSelected ? "bg-gray-200 font-semibold dark:bg-slate-300 dark:hover:bg-slate-200 dark:pressed:bg-slate-100 dark:text-black forced-colors:!bg-[Highlight] forced-colors:!text-[HighlightText]" : "hover:bg-gray-200 pressed:bg-gray-300 dark:hover:bg-zinc-500 dark:pressed:bg-zinc-400 dark:text-zinc-100 forced-colors:!bg-[ButtonFace] forced-colors:!text-[ButtonText] hover:text-primary hover:font-semibold",
  // borderRadius variants
  borderRadius === "left" && "rounded-l-sm", borderRadius === "right" && "rounded-r-sm",
  // border variants
  borderRight && "border-r", borderLeft && "border-l",
  // isDisabled variant
  isDisabled && "bg-gray-100 dark:bg-zinc-800 forced-colors:!bg-[ButtonFace] text-gray-300 dark:text-zinc-600 forced-colors:!text-[GrayText] border-black/5 dark:border-white/5 forced-colors:border-[GrayText]",
  // size variants
  size === "sm" && "h-[theme(controlElementSize.sm)]", size === "md" && "h-[theme(controlElementSize.md)]", size === "lg" && "h-[theme(controlElementSize.lg)]",
  // Additional className
  className);
};
export interface toggleButtonProps extends toggleButtonVariants {
  className?: string;
  children: React.ReactNode;
  size: "sm" | "md" | "lg";
  onChange: (isSelected: boolean) => void;
  onPress?: () => void;
  isSelected: boolean;
}
export function ToggleButton(props: toggleButtonProps) {
  const {
    className,
    children,
    size,
    onPress,
    ...rest
  } = props;
  return <RACToggleButton onPress={onPress} {...rest} className={getToggleButtonClass({
    ...rest,
    size,
    className
  })} data-sentry-element="RACToggleButton" data-sentry-component="ToggleButton" data-sentry-source-file="ToggleButton.tsx">
      {children}
    </RACToggleButton>;
}