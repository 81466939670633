import { ReactNode } from "react";
import { YFormatType } from "@/edges/utils/charts";

// Base data point interface that can be extended
export interface BaseDataPoint {
  xValue: Date;
  yValue: number;
}

// Extended data point with optional category
export interface CategoryDataPoint extends BaseDataPoint {
  categoryValue?: string;
}

// Common margin configuration
export interface ChartMargin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

// Base props shared by all charts
export interface BaseChartProps {
  parentWidth: number;
  parentHeight: number;
  xLabel: string;
  yLabel: string;
  colorIndex?: number;
  yMin?: number;
  yMax?: number;
  yFormatType: YFormatType;
}

// Props specific to Area Chart
export interface AreaChartProps extends BaseChartProps {
  // data is optional to allow for empty state
  data?: BaseDataPoint[];
}

// Props specific to Grouped Bar Chart
export interface GroupedBarChartProps extends BaseChartProps {
  data: CategoryDataPoint[];
  categoryLabel: string;
  categoryColors?: {
    category: string;
    fillColor: string;
  }[];
}

// Common tooltip content props
export interface TooltipProps {
  tooltipContent?: ReactNode;
  tooltipClassName?: string;
}

// Default values that can be used across charts
export const defaultMargin: ChartMargin = {
  top: 20,
  right: 20,
  bottom: 50,
  left: 50
};

// Utility type for axis configuration
export interface AxisConfig {
  numTicks?: number;
  tickFormat?: (value: any) => string;
  className?: string;
  stroke?: string;
  tickStroke?: string;
}

// Shared class names
export const chartClassNames = {
  background: "absolute inset-0 rounded-[14px] bg-componentBg dark:bg-componentBgDark",
  container: "relative flex h-full flex-col"
} as const;

/**
 * Shared configuration for chart tooltips
 * @property tooltipData - The data point to display in the tooltip
 * @property tooltipLeft - Horizontal position of the tooltip
 * @property tooltipTop - Vertical position of the tooltip
 * @property showTooltip - Callback to display the tooltip
 * @property hideTooltip - Callback to hide the tooltip
 */
export interface SharedTooltipProps {
  tooltipData?: BaseDataPoint | CategoryDataPoint;
  tooltipLeft?: number;
  tooltipTop?: number;
  showTooltip: (args: {
    tooltipData: BaseDataPoint | CategoryDataPoint;
    tooltipLeft: number;
    tooltipTop: number;
  }) => void;
  hideTooltip: () => void;
}