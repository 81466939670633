import tailwindConfig from "../../tailwind.config";

const useTailwindTheme = () => {
  // Access the theme directly from Tailwind config
  const theme = tailwindConfig?.theme?.extend;

  const getThemeValue = (keyPath: string) => {
    return keyPath.split(".").reduce((acc, key) => acc && acc[key], theme);
  };

  return { getThemeValue, theme };
};

export { useTailwindTheme };
