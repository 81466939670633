import React from "react";
import { RangeCalendar as AriaRangeCalendar, RangeCalendarProps as AriaRangeCalendarProps, CalendarCell, CalendarGrid, CalendarGridBody, DateValue, Text } from "react-aria-components";
import { CalendarGridHeader, CalendarHeader } from "@/edges/ui/Calendar";
import { focusRing } from "@/edges/utils";
export interface RangeCalendarProps<T extends DateValue> extends Omit<AriaRangeCalendarProps<T>, "visibleDuration"> {
  errorMessage?: string;
}
function getCellClassName({
  selectionState,
  isDisabled,
  isFocusVisible
}: {
  selectionState: "none" | "middle" | "cap";
  isDisabled?: boolean;
  isFocusVisible?: boolean;
}) {
  const baseClasses = `${focusRing} w-full h-full flex items-center justify-center rounded-sm forced-color-adjust-none dark:text-zinc-200 font-semibold`;
  const selectionStateClasses = {
    none: "text-text group-hover:bg-gray-200 dark:group-hover:bg-zinc-700 group-pressed:bg-gray-200 dark:group-pressed:bg-zinc-600",
    middle: ["group-hover:bg-gray-300 dark:group-hover:bg-blue-900 forced-colors:group-hover:bg-[Highlight]", "group-invalid:group-hover:bg-red-200 dark:group-invalid:group-hover:bg-red-900 forced-colors:group-invalid:group-hover:bg-[Mark]", "group-pressed:bg-blue-300 dark:group-pressed:bg-blue-800 forced-colors:group-pressed:bg-[Highlight] forced-colors:text-[HighlightText]", "group-invalid:group-pressed:bg-red-300 dark:group-invalid:group-pressed:bg-red-800 forced-colors:group-invalid:group-pressed:bg-[Mark]"].join(" "),
    cap: "bg-text group-invalid:bg-red-600 forced-colors:bg-[Highlight] forced-colors:group-invalid:bg-[Mark] text-white forced-colors:text-white"
  };
  const disabledClasses = isDisabled ? "text-textFaint dark:text-zinc-600 forced-colors:text-textFaint font-light" : "";
  return [baseClasses, selectionStateClasses[selectionState], disabledClasses].filter(Boolean).join(" ");
}
export function RangeCalendar<T extends DateValue>({
  errorMessage,
  ...props
}: RangeCalendarProps<T>) {
  return <AriaRangeCalendar {...props} data-sentry-element="AriaRangeCalendar" data-sentry-component="RangeCalendar" data-sentry-source-file="RangeCalendar.tsx">
      <CalendarHeader data-sentry-element="CalendarHeader" data-sentry-source-file="RangeCalendar.tsx" />
      <CalendarGrid className="w-full [&_td]:px-0" data-sentry-element="CalendarGrid" data-sentry-source-file="RangeCalendar.tsx">
        <CalendarGridHeader data-sentry-element="CalendarGridHeader" data-sentry-source-file="RangeCalendar.tsx" />
        <CalendarGridBody data-sentry-element="CalendarGridBody" data-sentry-source-file="RangeCalendar.tsx">
          {date => <CalendarCell date={date} className="selection-start:rounded-s-end selection-end:rounded-e-end [td:last-child_&]:rounded-e-end group h-9 w-9 cursor-default text-sm outline outline-0 outside-month:text-gray-300 selected:bg-gray-100 invalid:selected:bg-red-100 dark:selected:bg-blue-700/30 dark:invalid:selected:bg-red-700/30 forced-colors:selected:bg-[Highlight] forced-colors:selected:text-white forced-colors:invalid:selected:bg-[Mark] [td:first-child_&]:rounded-s-full">
              {({
            formattedDate,
            isSelected,
            isSelectionStart,
            isSelectionEnd,
            isFocusVisible,
            isDisabled
          }) => <span className={getCellClassName({
            selectionState: isSelected && (isSelectionStart || isSelectionEnd) ? "cap" : isSelected ? "middle" : "none",
            isDisabled,
            isFocusVisible
          })}>
                  {formattedDate}
                </span>}
            </CalendarCell>}
        </CalendarGridBody>
      </CalendarGrid>
      {errorMessage && <Text slot="errorMessage" className="text-sm text-red-600">
          {errorMessage}
        </Text>}
    </AriaRangeCalendar>;
}