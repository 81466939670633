import React from "react";
import Color from "color";
export interface LegendItem {
  category: string;
  fill: string;
  gradientId?: string;
}
export interface LegendProps {
  items: LegendItem[];
}
export const Legend: React.FC<LegendProps> = ({
  items
}) => {
  return <div className="relative flex flex-wrap items-center gap-12 overflow-hidden px-4" data-sentry-component="Legend" data-sentry-source-file="Legend.tsx">
      {items.map((item, index) => <div key={`legend-${index}`} className="flex items-center gap-2">
          <svg width="20" height="20">
            <rect width="20" height="20" rx="2" fill={item.gradientId ? `url(#${item.gradientId})` : item.fill} stroke={Color(item.fill).darken(0.6).hex()} strokeWidth="1" />
          </svg>
          <span className="text-sm text-gray-600" dangerouslySetInnerHTML={{
        __html: item.category
      }} />
        </div>)}
    </div>;
};