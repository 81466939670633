import { Legend } from "@/edges/ui/Legend";
import { ActionMenu } from "@/edges/ui/ActionMenu";
import { Icon } from "@/edges/ui/Icon";
import { Button } from "@/edges/ui/Button";
type ExportType = "csv" | "svg" | "png";
interface LegendItem {
  category: string;
  fill: string;
}
export interface ChartBottomBarProps {
  items: LegendItem[];
  onExport: (type: ExportType) => void;
}
const exportItems = [{
  id: "csv",
  name: "CSV"
}, {
  id: "svg",
  name: "SVG"
}, {
  id: "png",
  name: "PNG"
}];
export const ChartBottomBar = ({
  items,
  onExport
}: ChartBottomBarProps) => {
  return <div className="mt-4 flex items-center justify-between overflow-y-hidden" data-sentry-component="ChartBottomBar" data-sentry-source-file="ChartBottomBar.tsx">
      <Legend items={items} data-sentry-element="Legend" data-sentry-source-file="ChartBottomBar.tsx" />
      <ActionMenu variant="secondary" items={exportItems} align="right" textAlign="right" size="sm" onAction={(item: {
      id: string | number;
    }) => onExport(item.id as ExportType)} buttonComponent={<Button variant="secondary" size="sm">
            <Icon name="Export" size={18} />
            Export
          </Button>} data-sentry-element="ActionMenu" data-sentry-source-file="ChartBottomBar.tsx" />
    </div>;
};