import { useMediaQuery } from "react-responsive";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "~/tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);
const breakpoints = fullConfig.theme?.screens || {};

export const useIsMobile = () => useMediaQuery({ maxWidth: breakpoints.sm });
export const useIsTablet = () =>
  useMediaQuery({ minWidth: breakpoints.sm, maxWidth: breakpoints.md });
export const useIsDesktop = () => useMediaQuery({ minWidth: breakpoints.lg });

// You can add more custom hooks for specific breakpoints as needed
