import { Check } from "lucide-react";
import React from "react";
import { ListBox as AriaListBox, ListBoxItem as AriaListBoxItem, ListBoxProps as AriaListBoxProps, Collection, Header, ListBoxItemProps, Section, SectionProps, composeRenderProps } from "react-aria-components";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";
interface ListBoxProps<T> extends Omit<AriaListBoxProps<T>, "layout" | "orientation"> {}
export function ListBox<T extends object>({
  children,
  ...props
}: ListBoxProps<T>) {
  return <AriaListBox {...props} className={composeTailwindRenderProps(props.className, "border border-gray-300 outline-0 dark:border-zinc-600")} data-sentry-element="AriaListBox" data-sentry-component="ListBox" data-sentry-source-file="ListBox.tsx">
      {children}
    </AriaListBox>;
}
export function getItemStyles({
  isSelected,
  isDisabled,
  size
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
}) {
  let styles = `${focusRing} group relative flex items-center gap-8 cursor-default select-none py-1.5 px-2.5 will-change-transform text-sm forced-color-adjust-none`;
  if (isSelected) {
    styles += " bg-highlight text-white forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] [&:has(+[data-selected])]:rounded-b-none [&+[data-selected]]:rounded-t-none -outline-offset-4 outline-white dark:outline-white forced-colors:outline-[HighlightText]";
  } else {
    styles += " dark:text-zinc-300 hover:bg-slate-200 dark:hover:bg-zinc-700 -outline-offset-2";
  }
  if (isDisabled) {
    styles += " text-slate-300 dark:text-zinc-600 forced-colors:text-[GrayText]";
  }
  if (size === "sm") {
    styles += " py-1 text-xs";
  }
  return styles;
}
export function ListBoxItem(props: ListBoxItemProps) {
  const textValue = props.textValue || (typeof props.children === "string" ? props.children : undefined);
  return <AriaListBoxItem {...props} textValue={textValue} className={props => getItemStyles(props)} data-sentry-element="AriaListBoxItem" data-sentry-component="ListBoxItem" data-sentry-source-file="ListBox.tsx">
      {composeRenderProps(props.children, children => <>
          {children}
          <div className="absolute bottom-0 left-4 right-4 hidden h-px bg-white/20 forced-colors:bg-[HighlightText] [.group[data-selected]:has(+[data-selected])_&]:block" />
        </>)}
    </AriaListBoxItem>;
}
export function getDropdownItemStyles({
  isDisabled,
  isFocused,
  isOpen
}: {
  isDisabled?: boolean;
  isFocused?: boolean;
  isOpen?: boolean;
}) {
  let styles = "group flex items-center gap-4 cursor-default select-none py-2 pl-3 pr-1 outline outline-0 text-sm forced-color-adjust-none hover:bg-highlight dark:hover:bg-highlight";
  if (isDisabled) {
    styles += " text-gray-300 dark:text-zinc-600 forced-colors:text-[GrayText]";
  } else {
    styles += " text-gray-900 dark:text-zinc-100";
  }
  if (isFocused) {
    styles += " forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]";
  }
  if (!isFocused && isOpen) {
    styles += "  ";
  }
  return styles;
}
export function DropdownItem(props: ListBoxItemProps) {
  const textValue = props.textValue || (typeof props.children === "string" ? props.children : undefined);
  return <AriaListBoxItem {...props} textValue={textValue} className={props => getDropdownItemStyles(props)} data-sentry-element="AriaListBoxItem" data-sentry-component="DropdownItem" data-sentry-source-file="ListBox.tsx">
      {composeRenderProps(props.children, (children, {
      isSelected
    }) => <>
          <span className="flex flex-1 items-center gap-2 truncate font-normal group-selected:font-semibold">
            {children}
          </span>
          <span className="flex w-5 items-center">
            {isSelected && <Check className="h-4 w-4" />}
          </span>
        </>)}
    </AriaListBoxItem>;
}
export interface DropdownSectionProps<T> extends SectionProps<T> {
  title?: string;
}
export function DropdownSection<T extends object>(props: DropdownSectionProps<T>) {
  return <Section className="after:block after:h-[5px] after:content-[''] first:-mt-[5px]" data-sentry-element="Section" data-sentry-component="DropdownSection" data-sentry-source-file="ListBox.tsx">
      <Header className="sticky -top-[5px] z-10 -mx-1 -mt-px truncate border-y bg-gray-100/60 px-4 py-1 text-sm font-semibold text-gray-500 backdrop-blur-md supports-[-moz-appearance:none]:bg-gray-100 dark:border-y-zinc-700 dark:bg-zinc-700/60 dark:text-zinc-300 [&+*]:mt-1" data-sentry-element="Header" data-sentry-source-file="ListBox.tsx">
        {props.title}
      </Header>
      <Collection items={props.items} data-sentry-element="Collection" data-sentry-source-file="ListBox.tsx">{props.children}</Collection>
    </Section>;
}