import { extent, max, min } from "d3-array";
import { scaleLinear, scaleTime } from "@visx/scale";
import { tokW, tokWh, toAmps, toTemperature } from "../../../../utils";
import { defaultMargin } from "../../types/ChartProps";

export interface BaseDataPoint {
  xValue: Date;
  yValue: number;
}

export type YFormatType =
  | "number"
  | "percent"
  | "kWh"
  | "kW"
  | "amperes"
  | "temperature"
  | "percentageChange"
  | "decimal"
  | "currency"
  | "scientific"
  | "integer"
  | "logarithmic"
  | "timeDuration"
  | "compact"
  | "si"
  | "bytes"
  | "rate"
  | "ordinal"
  | "date"
  | "largeCurrency"
  | "coordinates"
  | "ranked";

export interface YFormatSettings {
  format: (value: number) => string;
  min?: number;
  max?: number;
  tickInterval?: number;
  tickFormat?: (value: number) => string;
}

export function getYFormatSettings(
  formatType: YFormatType,
  currencySymbol = "$",
): YFormatSettings {
  switch (formatType) {
    case "number":
      return {
        format: (value) => value.toLocaleString(),
        tickFormat: (value) => value.toLocaleString(),
      };
    case "percent":
      return {
        format: (value) => `${value.toFixed(1)}%`,
        tickFormat: (value) => `${value.toFixed(0)}%`,
        min: 0,
        max: 100,
        tickInterval: 20,
      };
    case "kWh":
      return {
        format: (value) => `${tokWh(value)}`,
        tickFormat: (value) => `${Math.round(value / 1000).toFixed(1)}`,
      };
    case "kW":
      return {
        format: (value) => `${tokW(value)}`,
        tickFormat: (value) => `${Math.round(value / 1000).toFixed(1)}`,
      };
    case "amperes":
      return {
        format: (value) => `${toAmps(value)}`,
        tickFormat: (value) => `${toAmps(value)}`,
      };
    case "temperature":
      return {
        format: (value) => `${toTemperature(value)}`,
        tickFormat: (value) => `${value.toFixed(0)}`,
      };
    case "percentageChange":
      return {
        format: (value) =>
          `${value > 0 ? "+" : ""}${(value * 100).toFixed(1)}%`,
        tickFormat: (value) =>
          `${value > 0 ? "+" : ""}${(value * 100).toFixed(0)}%`,
        min: -100,
        max: 100,
      };
    case "decimal":
      return {
        format: (value) => value.toFixed(2),
        tickFormat: (value) => value.toFixed(1),
      };
    case "currency":
      return {
        format: (value) => `${currencySymbol}${value.toLocaleString()}`,
        tickFormat: (value) => `${currencySymbol}${value.toLocaleString()}`,
      };
    case "scientific":
      return {
        format: (value) => value.toExponential(2),
        tickFormat: (value) => value.toExponential(1),
      };
    case "integer":
      return {
        format: (value) => Math.round(value).toLocaleString(),
        tickFormat: (value) => Math.round(value).toLocaleString(),
      };
    case "logarithmic":
      return {
        format: (value) => value.toExponential(1),
        tickFormat: (value) => value.toExponential(0),
        min: 1,
        tickInterval: 10,
      };
    case "timeDuration":
      return {
        format: (value) =>
          `${Math.floor(value / 60)}:${(value % 60).toString().padStart(2, "0")} min`,
        tickFormat: (value) => `${Math.floor(value / 60)}m`,
        min: 0,
      };
    case "compact":
      return {
        format: (value) =>
          new Intl.NumberFormat("en", { notation: "compact" }).format(value),
        tickFormat: (value) =>
          new Intl.NumberFormat("en", { notation: "compact" }).format(value),
      };
    case "si":
      return {
        format: (value) =>
          new Intl.NumberFormat("en", {
            notation: "compact",
            maximumFractionDigits: 2,
          }).format(value),
        tickFormat: (value) =>
          new Intl.NumberFormat("en", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(value),
      };
    case "bytes":
      return {
        format: (value) => {
          const units = ["B", "KB", "MB", "GB", "TB"];
          let unitIndex = 0;
          let val = value;
          while (val >= 1024 && unitIndex < units.length - 1) {
            val /= 1024;
            unitIndex++;
          }
          return `${val.toFixed(1)} ${units[unitIndex]}`;
        },
        tickFormat: (value) => {
          const units = ["B", "KB", "MB", "GB", "TB"];
          let unitIndex = 0;
          let val = value;
          while (val >= 1024 && unitIndex < units.length - 1) {
            val /= 1024;
            unitIndex++;
          }
          return `${val.toFixed(0)} ${units[unitIndex]}`;
        },
        min: 0,
      };
    case "rate":
      return {
        format: (value) => `${value.toFixed(1)} req/sec`,
        tickFormat: (value) => `${value.toFixed(0)}/s`,
      };
    case "ordinal":
      return {
        format: (value) => {
          const suffixes = ["th", "st", "nd", "rd"];
          const v = value % 100;
          return (
            value + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
          );
        },
        tickFormat: (value) => value.toString(),
        min: 1,
      };
    case "date":
      return {
        format: (value) =>
          new Intl.DateTimeFormat("en", { dateStyle: "medium" }).format(
            new Date(value),
          ),
        tickFormat: (value) =>
          new Intl.DateTimeFormat("en", { dateStyle: "short" }).format(
            new Date(value),
          ),
        min: 0,
      };
    case "largeCurrency":
      return {
        format: (value) => `${currencySymbol}${(value / 1e6).toFixed(2)}M`,
        tickFormat: (value) => `${currencySymbol}${(value / 1e6).toFixed(0)}M`,
      };
    case "coordinates":
      return {
        format: (value) =>
          `${Math.abs(value).toFixed(3)}° ${value >= 0 ? "N" : "S"}`,
        tickFormat: (value) =>
          `${Math.abs(value).toFixed(1)}° ${value >= 0 ? "N" : "S"}`,
        min: -90,
        max: 90,
      };
    case "ranked":
      return {
        format: (value) => `Rank: ${value}`,
        tickFormat: (value) => value.toString(),
        min: 1,
      };
    default:
      return {
        format: (value) => value.toLocaleString(),
        tickFormat: (value) => value.toLocaleString(),
      };
  }
}

export function createXScale(data: any[], width: number) {
  const dateExtent = extent(data, (d) => d.xValue) as [Date, Date];
  return scaleTime({
    range: [defaultMargin.left, width - defaultMargin.right],
    domain: dateExtent,
    nice: true,
  });
}

export function createYScale(
  data: any[],
  height: number,
  yFormatType: YFormatType,
) {
  const yFormatSettings = getYFormatSettings(yFormatType);
  const dataYMin = min(data, (d) => d.yValue) || 0;
  const dataYMax = max(data, (d) => d.yValue) || 0;
  const yMin =
    yFormatSettings.min !== undefined
      ? yFormatSettings.min
      : dataYMin >= 0
        ? 0 // 0 if all positive values, min is 0
        : dataYMin * 1.1; // else scale the negative values by 10% to make room for the y-axis values
  const yMax =
    yFormatSettings.max !== undefined
      ? yFormatSettings.max
      : dataYMax <= 0
        ? 0 // If all values are negative, max is 0
        : dataYMax * 1.1; // else scale the positive values by 10% to make room for the y-axis values
  return scaleLinear({
    range: [height - defaultMargin.bottom, defaultMargin.top],
    domain: [yMin, yMax],
    nice: true,
  });
}
