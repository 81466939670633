import React from "react";
import { AxisLeft as VisxAxisLeft } from "@visx/axis";
import { getYFormatSettings } from "@/edges/utils/charts";
interface AxisLeftProps {
  left: number;
  scale: any;
  yFormatType: string;
}
const AxisLeft: React.FC<AxisLeftProps> = ({
  left,
  scale,
  yFormatType
}) => <VisxAxisLeft left={left} scale={scale} numTicks={5} stroke="#E5E7EB" tickStroke="#E5E7EB" tickFormat={value => getYFormatSettings(yFormatType).tickFormat(value)} tickLabelProps={() => ({
  className: "font-body text-gray-500 text-xs",
  textAnchor: "end",
  dx: "-0.75em",
  dy: "0.275em"
})} data-sentry-element="VisxAxisLeft" data-sentry-component="AxisLeft" data-sentry-source-file="AxisLeft.tsx" />;
export { AxisLeft };